import { createContext } from 'react';

export type AuthConfigContextInterface = {
  isAuth0Hosted: boolean;
  domain: string;
  clientID: string;
  redirectUri?: string;
  responseType?: string;
} & {
  [key: string]: unknown;
};

const initialConfigContext: AuthConfigContextInterface = {
  domain: '<YOUR AUTH0 DOMAIN>',
  clientID: '<YOUR AUTH0 CLIENT ID>',
  isAuth0Hosted: false,
};

const AuthConfigContext = createContext(initialConfigContext);

export default AuthConfigContext;
