import { Formik } from 'formik';

import { Box, Button, Heading, Input, Stack, Text } from '@vinomofo/components';
import useApplicationState from '../../../hooks/use-application-state';
import useAuth from '../../../hooks/use-auth';
import { validate, validateEmail } from '../../../utils/form-validation';
import LoginWithCredentialsLink from '../../shared/_login-with-credentials';
import { useState } from 'react';
import styled from 'styled-components';
import JoinInstead from '../../shared/_join-instead';
import { trackEvent } from '../../../utils/track-event';
import { sendPasswordResetEmailUserWithSocialAuth0Only } from '../../../utils/identity-api-client';
import { FormikHelpers } from 'formik';
import { useHistory, useParams } from 'react-router-dom';

const StyledInput = styled(Input)`
  border: 0;
  border-bottom: 2px solid ${({ theme }) => theme.colors.divider};
  text-align: center;
  background: none;
  border-radius: 0;
  color: white;

  ::placeholder {
    color: white;
  }

  &:focus {
    box-shadow: none;
    border-color: ${({ theme }) => theme.colors.brand};

    ::placeholder {
      opacity: 0;
    }
  }
`;

interface RouteParams {
  email?: string;
}

const Render = () => {
  const { forgotPassword } = useAuth();
  const { setError } = useApplicationState();
  const [message, setMessage] = useState<string | unknown>('');
  const history = useHistory();

  interface FormValues {
    email: string;
    password: string;
  }

  const handleSubmit = async (values: FormValues, actions: FormikHelpers<FormValues>): Promise<void> => {
    const { email } = values;

    try {
      const encodedEmail = encodeURIComponent(email);
      const resp: string | unknown = await forgotPassword({ email });

      trackEvent('Password Reset', {
        category: 'Auth',
        action: 'Password Reset Request',
      });

      setMessage(resp);
      history.push(`/auth/login/forgot-password-confirmation/${encodedEmail}`);
    } catch (error: any) {
      setError(error.description);
      trackEvent('Password Reset', {
        category: 'Auth',
        action: 'Password Reset Failed',
      });
    } finally {
      actions.setSubmitting(false);
      await sendPasswordResetEmailUserWithSocialAuth0Only(email);
    }
  };

  const { email: emailParam }: RouteParams = useParams();
  const decodedEmail = emailParam && decodeURIComponent(emailParam);
  return (
    <Formik
      validateOnMount={true}
      initialValues={{ email: decodedEmail || '', password: '' }}
      validate={validate([validateEmail('email')])}
      onSubmit={handleSubmit}
    >
      {({ isValid, handleSubmit, handleChange, handleBlur, values, isSubmitting }) => (
        <Stack space={3}>
          <Text
            fontFamily="heading"
            fontSize={['22px', '22px', '28px']}
            fontWeight={700}
            color="white"
            textAlign="center"
          >
            Reset your password
          </Text>

          <Box as="form" onSubmit={handleSubmit} display="flex" justifyContent="center">
            <Box width="100%" maxWidth="360px">
              <Stack space={2}>
                <Box>
                  <StyledInput
                    type="email"
                    name="email"
                    placeholder="Provide your email address"
                    autoComplete="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Box>

                <Button type="submit" variant="primary" disabled={!isValid || isSubmitting}>
                  {isSubmitting ? 'Sending...' : 'Send Password Reset Email'}
                </Button>

                {message && typeof message === 'string' && (
                  <Heading as="h4" fontSize="16px">
                    {message}
                  </Heading>
                )}

                <Stack space={2}>
                  <JoinInstead />
                  <LoginWithCredentialsLink message="Can't access your email? Too many unreads?" />
                </Stack>
              </Stack>
            </Box>
          </Box>
        </Stack>
      )}
    </Formik>
  );
};

export default Render;
