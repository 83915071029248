import { Link as RouteLink } from 'react-router-dom';
import styled from 'styled-components';

import { Text } from '@vinomofo/components';
import { getHashParams } from '../../utils/hash-params';

const Link = styled(RouteLink)`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 500;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const Render = () => {
  const hashParams = getHashParams(window.location.hash);
  const returnTo = hashParams.returnTo || 'https://vinomofo.com';
  const email = hashParams.email;
  let toURL = `/auth/join?returnTo=${returnTo}`;
  if (email) toURL = `${toURL}&email=${email}`;

  return (
    <div>
      <Text fontSize="12px">
        <Link to={toURL}>New to the &apos;Fo?</Link>
      </Text>
    </div>
  );
};

export default Render;
