export type LoginCredentials = { email: string; password: string };

type PasswordlessOptionsEmail = { email: string };
type PasswordlessOptionsPhone = { phone: string };
export type PasswordlessOptions = PasswordlessOptionsEmail | PasswordlessOptionsPhone;

type VerifyPasswordlessOptionsEmail = { email: string; code: string };
type VerifyPasswordlessOptionsPhone = { phone: string; code: string };
export type VerifyPasswordlessOptions = VerifyPasswordlessOptionsEmail | VerifyPasswordlessOptionsPhone;

export const isPasswordlessEmail = (opts: PasswordlessOptions): opts is PasswordlessOptionsEmail => {
  return (opts as PasswordlessOptionsEmail).email !== undefined;
};

export const isVerifyPasswordlessEmail = (opts: VerifyPasswordlessOptions): opts is VerifyPasswordlessOptionsEmail => {
  return (opts as VerifyPasswordlessOptionsEmail).email !== undefined;
};
