export const trackUserLoginOption = (provider: string) => {
  if (typeof window !== 'undefined' && typeof window.analytics !== 'undefined') {
    window.analytics.track('User Login Option', {
      category: 'Auth',
      provider: provider,
    });
  }
};

export const trackEvent = (eventName: string, events: any): void => {
  if (typeof window !== 'undefined' && typeof window.analytics !== 'undefined') {
    window.analytics.track(eventName, events);
  }
};
