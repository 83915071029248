import { useState, useRef, useEffect, ChangeEventHandler, KeyboardEventHandler, useMemo } from 'react';
import styled from 'styled-components';
import clamp from '../../utils/clamp';

const Container = styled('div')`
  display: flex;
`;

const InputWrapper = styled('div')`
  border: 1px solid rgba(0, 0, 0, 0.2);

  &:not(:first-child) {
    border-left: none;
  }

  &:first-child {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  &:last-child {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  &:focus-within {
    border: 1px solid #48a90f;
    box-shadow: 0 0 0 1px #48a90f;
  }
`;

const Input = styled('input').attrs((/* props */) => ({
  type: 'tel',
  pattern: '[0-9]*',
}))`
  height: 80px;
  width: 100%;
  font-size: 30px;
  line-height: 56px;
  text-align: center;
  border: none;
  background: none;
  box-shadow: none;

  &:focus {
    box-shadow: none;
    outline: none;
  }
`;

const CODE_LENGTH = 6;

const getIndexOfElement = (element: HTMLInputElement): number => {
  return parseInt(element.dataset.id!);
};

const getNextIndex = (index: number): number => {
  return clamp(index + 1, 0, CODE_LENGTH - 1);
};

const getPrevIndex = (index: number): number => {
  return clamp(index - 1, 0, CODE_LENGTH - 1);
};

type Props = {
  defaultValue?: string;
  onChange: (code: string) => void;
  onComplete: (code: string) => void;
};

const Render = ({ defaultValue = '', onChange, onComplete }: Props) => {
  const defaultValues = Array.from(defaultValue).concat(new Array(CODE_LENGTH).fill('')).slice(0, CODE_LENGTH);
  const [values, setValues] = useState<string[]>(defaultValues);

  const inputRefs = values.map(() => {
    return useRef<HTMLInputElement>(null);
  });

  const valueString = useMemo(() => {
    return values.join('');
  }, [values]);

  useEffect(() => {
    if (undefined === onChange) return;
    onChange(valueString);
  }, [values, onChange]);

  const onInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const index = getIndexOfElement(e.target);

    // only allow numbers
    const value = e.target.value.replace(/[^\d]/gi, '');

    // clone values for updates
    const updatedValues = Array.from(values);

    let nextIndex;
    if (value.length > 1) {
      // handle pasting more than one number
      const additions = Array.from(value);
      updatedValues.splice(index, 0, ...additions);
      nextIndex = getNextIndex(index + additions.length - 1);
    } else {
      // handle entering a single number
      updatedValues[index] = value;
      nextIndex = getNextIndex(index);
    }

    setValues(updatedValues.slice(0, CODE_LENGTH));

    const nextInput = inputRefs[nextIndex];
    nextInput.current?.focus();
  };

  const onKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
    const index = getIndexOfElement(e.currentTarget);
    const prevIndex = getPrevIndex(index);
    const nextIndex = getNextIndex(index);

    const prev = inputRefs[prevIndex];
    const next = inputRefs[nextIndex];

    const selectionStart = e.currentTarget.selectionStart;
    const selectionEnd = e.currentTarget.selectionEnd;

    if (e.key === 'Backspace') {
      e.preventDefault();

      const chars = [...values];

      if (chars[index].length) {
        if (1 === selectionStart || 1 === selectionEnd) {
          chars[index] = '';
        }
      } else {
        prev.current?.focus();
        chars[prevIndex] = '';
      }

      setValues(chars);
    }

    if (e.key === 'ArrowLeft') {
      e.preventDefault();
      prev.current?.focus();
    }

    if (e.key === 'ArrowRight') {
      e.preventDefault();
      next.current?.focus();
    }

    if (e.key === 'Enter') {
      onComplete(valueString);
    }
  };

  return (
    <Container>
      {values.map((value, index) => {
        return (
          <InputWrapper key={index}>
            <Input
              ref={inputRefs[index]}
              value={value}
              data-id={index}
              onChange={onInputChange}
              onKeyDown={onKeyDown}
            />
          </InputWrapper>
        );
      })}
    </Container>
  );
};

export default Render;
