import React from 'react';
import InputMask from 'react-input-mask';

import { Box, FieldErrorMessage, Input, Label, Text } from '@vinomofo/components';

interface TextFieldProps {
  name: string;
  value: string;
  label?: string;
  placeholder?: string;
  pattern?: string;
  autoComplete?: string;
  type?: string;
  hint?: string;
  touched?: boolean;
  isRequired?: boolean;
  errorMessage?: string;
  mask?: Array<string | RegExp>;
  leftAdornment?: JSX.Element;
  rightAdornment?: JSX.Element;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const Render = ({
  name,
  value,
  label,
  placeholder,
  pattern,
  autoComplete,
  type,
  hint,
  mask,
  touched = false,
  isRequired = true,
  leftAdornment,
  rightAdornment,
  errorMessage,
  ...rest
}: TextFieldProps): JSX.Element => {
  return (
    <Box>
      {label && (
        <Box mb={1}>
          <Label>
            {label}{' '}
            {!isRequired && (
              <Text as="span" fontFamily="heading" fontSize="12px">
                optional
              </Text>
            )}
          </Label>
          {hint && <Text fontSize="12px">{hint}</Text>}
        </Box>
      )}

      {mask ? (
        <InputMask
          type={type}
          name={name}
          autoComplete={autoComplete}
          placeholder={placeholder}
          pattern={pattern}
          defaultValue={value}
          mask={mask}
          maskPlaceholder={null}
          {...rest}
        >
          <Input />
        </InputMask>
      ) : (
        <Box display="flex" alignItems="center">
          {leftAdornment && <Box mr={2}>{leftAdornment}</Box>}
          <Input type={type} name={name} placeholder={placeholder} defaultValue={value} {...rest} />
          {rightAdornment && <Box ml={2}>{rightAdornment}</Box>}
        </Box>
      )}

      <Box minHeight="24px" position="relative">
        {touched && errorMessage && (
          <Box position="absolute" top="4px">
            <FieldErrorMessage message={errorMessage} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Render;
