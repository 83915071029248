import React from 'react';
import ReactDOM from 'react-dom';
import { dataDogEnabled } from './utils/environment-helpers';
import { datadogLogs } from '@datadog/browser-logs';

import App from './app';

declare global {
  interface Window {
    auth0Config: string;
    analytics: SegmentAnalytics.AnalyticsJS;
  }
}

if (dataDogEnabled()) {
  datadogLogs.init({
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN || '',
    site: 'datadoghq.com',
    forwardErrorsToLogs: true,
    sampleRate: 100,
    env: process.env.REACT_APP_VM_ENV,
    service: 'vino-identity',
  });
}

try {
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root'),
  );
} catch (e) {
  console.error('ERROR IN MAIN index', e);
}
