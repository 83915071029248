import { Box, Checkbox, FieldErrorMessage } from '@vinomofo/components';

interface CheckboxProps {
  name: string;
  value?: string;
  isChecked?: boolean;
  label?: string;
  touched?: boolean;
  errorMessage?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const Render = ({
  name,
  value,
  isChecked,
  label,
  touched = false,
  errorMessage,
  ...rest
}: CheckboxProps): JSX.Element => {
  return (
    <Box mb="20px">
      <Box display="flex" alignItems="baseline">
        <Box>
          <Checkbox label={label} name={name} defaultChecked={isChecked} value={value} {...rest} />
          {touched && errorMessage && <FieldErrorMessage message={errorMessage} />}
        </Box>
      </Box>
    </Box>
  );
};

export default Render;
