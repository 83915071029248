import { ApplicationState } from './state';

type Action = { type: 'SHIT_HAPPENED'; error: Error } | { type: 'KEEP_DATA'; formData: any };

export const reducer = (state: ApplicationState, action: Action): ApplicationState => {
  switch (action.type) {
    case 'SHIT_HAPPENED': {
      return {
        ...state,
        error: action.error,
      };
    }
    case 'KEEP_DATA': {
      return {
        ...state,
        formData: action.formData,
      };
    }
  }
};
