export const isDev = (): boolean => !isProd();

export const isProd = (): boolean => {
  try {
    return ['prod', 'production', 'staging'].includes(process.env.VM_ENV || '');
  } catch {
    return false;
  }
};

export const dataDogEnabled = () =>
  true ===
  (process &&
    process.env &&
    process.env.REACT_APP_DATADOG_ENABLED &&
    process.env.REACT_APP_DATADOG_ENABLED.toLowerCase() === 'true');
