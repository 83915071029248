import React from 'react';
import { Honeybadger, HoneybadgerErrorBoundary } from '@honeybadger-io/react';

const reportData = process.env.HONEYBADGER_REPORTDATA === 'true';
const config = {
  apiKey: process.env.HONEYBADGER_API_KEY,
  environment: process.env.NODE_ENV,
  developmentEnvironments: ['development'],
  reportData: reportData,
  filters: ['password', 'creditcard'],
};

const honeybadger = Honeybadger.configure(config);
const EmptyComponent = () => null;

const ErrorNotifierProvider = ({
  ErrorComponent = EmptyComponent,
  children,
}: {
  ErrorComponent?: React.ReactNode;
  children?: React.ReactNode;
}) => {
  return (
    <HoneybadgerErrorBoundary honeybadger={honeybadger} ErrorComponent={ErrorComponent}>
      {children}
    </HoneybadgerErrorBoundary>
  );
};

export default ErrorNotifierProvider;
