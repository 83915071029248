import { Box, Logo } from '@vinomofo/components';
import { WelcomeIcon } from '@vinomofo/icons';

const Render = () => {
  return (
    <Box display={['none', 'flex']} justifyContent="center">
      <Box width="100%" maxWidth="460px" py={4} px={4} color="white">
        <Box width={150} alignItems={'center'} margin="auto" mb={4}>
          <Logo vinoColor="white" />
        </Box>
        <WelcomeIcon width="100%" height="auto" />
      </Box>
    </Box>
  );
};

export default Render;
