import { WebAuth } from 'auth0-js';
import { ReactNode, useCallback, useContext, useState } from 'react';

import AuthClientContext, { AuthClientContextInterface } from './client-context';
import AuthConfigContext from './config-context';
import {
  LoginCredentials,
  PasswordlessOptions,
  VerifyPasswordlessOptions,
  isPasswordlessEmail,
  isVerifyPasswordlessEmail,
} from './types';

const AuthClientProvider = ({ children }: { children: ReactNode }) => {
  const config = useContext(AuthConfigContext);

  const [client] = useState(() => new WebAuth(config));

  const databaseConnection = 'vinomofo-au';

  const startPasswordless = useCallback(
    (options: PasswordlessOptions) => {
      return new Promise((resolve, reject) => {
        let params: ({ phoneNumber: string } | { email: string }) & {
          connection: string;
          send: 'link' | 'code';
        };

        if (isPasswordlessEmail(options)) {
          params = {
            connection: 'email',
            email: options.email,
            send: 'link',
          };
        } else {
          params = {
            connection: 'sms',
            phoneNumber: options.phone,
            send: 'code',
          };
        }

        client.passwordlessStart(params, (err, result) => {
          if (err) reject(err);
          resolve(result);
        });
      });
    },
    [client],
  );

  const startPasswordlessLogin = useCallback(
    (options: PasswordlessOptions) => {
      return startPasswordless(options);
    },
    [startPasswordless],
  );

  const startPasswordlessSignup = useCallback(
    (options: PasswordlessOptions) => {
      return startPasswordless(options);
    },
    [startPasswordless],
  );

  const verifyPasswordless = useCallback(
    (options: VerifyPasswordlessOptions) => {
      return new Promise((resolve, reject) => {
        let params: ({ phoneNumber: string } | { email: string }) & {
          connection: string;
          verificationCode: string;
        };

        if (isVerifyPasswordlessEmail(options)) {
          params = {
            connection: 'email',
            email: options.email,
            verificationCode: options.code,
          };
        } else {
          params = {
            connection: 'sms',
            phoneNumber: options.phone,
            verificationCode: options.code,
          };
        }
        client.passwordlessVerify(params, (err, result) => {
          if (err) reject(err);
          resolve(result);
        });
      });
    },
    [client],
  );

  const loginWithCredentials = useCallback(
    ({ email, password }: LoginCredentials) => {
      return new Promise((resolve, reject) => {
        const params = { email, password, realm: databaseConnection };

        client.login(params, (err, result) => {
          if (err) reject(err);
          resolve(result);
        });
      });
    },
    [client],
  );

  const forgotPassword = useCallback(
    (options: PasswordlessOptions) => {
      return new Promise((resolve, reject) => {
        let params: { email: string } & {
          connection: string;
        };
        if (isPasswordlessEmail(options)) {
          params = {
            connection: databaseConnection,
            email: options.email,
          };
          client.changePassword(params, (err, result) => {
            if (err) reject(err);
            resolve(result);
          });
        } else {
          reject(new Error('Forgot password with phone number is not supported'));
        }
      });
    },
    [client],
  );

  const loginWithSocialProvider = useCallback(
    (provider: string) => {
      return client.authorize({ connection: provider });
    },
    [client],
  );

  const context: AuthClientContextInterface = {
    startPasswordlessLogin,
    startPasswordlessSignup,
    verifyPasswordless,
    loginWithCredentials,
    loginWithSocialProvider,
    forgotPassword,
  };

  return <AuthClientContext.Provider value={context}>{children}</AuthClientContext.Provider>;
};

export default AuthClientProvider;
