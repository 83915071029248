import { ReactNode } from 'react';

import AuthClientProvider from './client-provider';
import AuthConfigProvider from './config-provider';

const AuthProvider = ({ children }: { children: ReactNode }) => {
  return (
    <AuthConfigProvider>
      <AuthClientProvider>{children}</AuthClientProvider>
    </AuthConfigProvider>
  );
};

export default AuthProvider;
