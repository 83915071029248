import { Box, Button, Stack, Text } from '@vinomofo/components';
import styled from 'styled-components';

const StyledText = styled(Text)`
  background-color: transparent !important;
  color: black !important;
`;

const EmailSuggestion = ({
  onAccept,
  onIgnore,
  text,
  style,
}: {
  onAccept: () => void;
  onIgnore: () => void;
  text: any;
  style: any;
}) => {
  return (
    <div style={{ cursor: 'pointer', width: 'fit-content', maxWidth: '100%' }}>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        backgroundColor="neonGreen"
        padding={'5px 5px 5px 20px'}
        style={{
          ...style,
          borderRadius: 20,
          borderTopLeftRadius: 0,
          flexWrap: 'wrap',
          gap: 5,
          maxWidth: '100%',
        }}
      >
        <Box style={{ marginRight: 10, wordWrap: 'break-word', maxWidth: '100%' }} onClick={onAccept}>
          <StyledText variant="sm">{text}</StyledText>
        </Box>
        <Box display="flex" flexDirection="row" justifyContent="space-between" style={{ gap: 10, height: 26 }}>
          <Box
            onClick={onAccept}
            style={{
              border: '1px solid #232627',
              padding: '1px 10px 0',
              borderRadius: 15,
            }}
          >
            <Text fontSize={1} fontWeight={'bold'}>
              Accept
            </Text>
          </Box>
          <Box
            onClick={onIgnore}
            style={{
              border: '1px solid #232627',
              padding: '1px 10px 0',
              borderRadius: 15,
            }}
          >
            <Text fontSize={1} fontWeight={'bold'}>
              Ignore
            </Text>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default EmailSuggestion;
