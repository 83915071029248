import { Honeybadger } from '@honeybadger-io/react';
import type { APIGatewayProxyEvent, Context } from 'aws-lambda';

const serviceName = 'vino-identity';
const reportData = process.env.HONEYBADGER_REPORTDATA === 'true';
const config = {
  apiKey: process.env.HONEYBADGER_API_KEY,
  environment: process.env.NODE_ENV,
  developmentEnvironments: ['development'],
  reportData: reportData,
  filters: ['password', 'creditcard'],
};

Honeybadger.configure(config);

export const ErrorNotifier = {
  notify: (error: Error, context: Record<string, unknown>): void => {
    if (!error) Honeybadger.notify(error, context);
  },
  setContext: (context: Record<string, unknown>): void => {
    Honeybadger.setContext(context);
  },
};

interface contextBuilderResponse {
  requestId?: string | null | undefined;
  path?: string | null | undefined;
  method?: string | null | undefined;
  requestBody?: string | null | undefined;
}

interface EventQueryStringParameters {
  [name: string]: string | undefined;
}
interface EventMultiValueQueryStringParameters {
  [name: string]: string[] | undefined;
}

export const contextBuilder = (
  ctx: Context | null | undefined,
  evt: Event | null | undefined,
): contextBuilderResponse => {
  return {
    ...(ctx && {
      requestId: ctx && ctx.awsRequestId,
      functionName: ctx.functionName,
      functionVersion: ctx.functionVersion,
      invokedFunctionArn: ctx.invokedFunctionArn,
    }),
    ...(evt && {
      queryStringParameters: evt.queryStringParameters,
      multiValueQueryStringParameters: evt.multiValueQueryStringParameters,
      path: evt.path,
      method: evt.httpMethod,
      requestBody: evt.body,
    }),
  };
};

export type Event = APIGatewayProxyEvent;

export const errorDataBuilder = (
  type: string,
  statusCode: number,
  error: unknown,
  context?: Context | undefined | null,
  event?: Event | undefined | null,
  customMessage?: string | undefined | null,
): {
  tags: string;
  name: string;
  message: string;
  environment: string;
  context: contextBuilderResponse;
  params: EventQueryStringParameters | EventMultiValueQueryStringParameters | null;
} => {
  return {
    tags: `${process.env.VM_MARKET}, ${type}, ${serviceName}, ${statusCode}`,
    name: `[${type}][${serviceName}]`,
    message: `[${statusCode}] ${customMessage || error}`,
    context: {
      ...contextBuilder(context, event),
    },
    environment: `${process.env.NODE_ENV}[${process.env.VM_MARKET}]`,
    params: (event && event.queryStringParameters) || (event && event.multiValueQueryStringParameters) || null,
  };
};
