import { Box, Input, Stack, Text } from '@vinomofo/components';
import useApplicationState from '../../../hooks/use-application-state';
import useAuth from '../../../hooks/use-auth';

import { useState } from 'react';
import styled from 'styled-components';

import { Link, useHistory, useParams } from 'react-router-dom';

const StyledInput = styled(Input)`
  border: 0;
  border-bottom: 2px solid ${({ theme }) => theme.colors.divider};
  text-align: center;
  background: none;
  border-radius: 0;

  :disabled {
    opacity: 1;
    background-color: transparent;
    cursor: default;
    color: white;
  }
`;

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 500;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

interface RouteParams {
  email: string;
}

const Render = () => {
  const { email }: RouteParams = useParams();
  const decodedEmail = decodeURIComponent(email);

  return (
    <Stack space={3}>
      <Text fontFamily="heading" fontSize={['22px', '22px', '28px']} fontWeight={700} color="white" textAlign="center">
        Check your email
      </Text>

      <Box height="1px" backgroundColor="#77B204" mx={[-4, -5]} />

      <Stack space={4}>
        <Text
          fontFamily="heading"
          fontSize={['18px', '20px', '22px']}
          fontWeight={700}
          color="white"
          textAlign="center"
          lineHeight={1.2}
        >
          To create a new password,
          <br /> follow the link we sent to
        </Text>
        <Box as="form" display="flex" justifyContent="center">
          <Box width="100%" maxWidth="360px">
            <Stack space={2}>
              <Box>
                <StyledInput
                  type="email"
                  name="email"
                  placeholder="Provide your email address"
                  autoComplete="email"
                  value={decodedEmail}
                  disabled={true}
                />
              </Box>
              <Stack space={2}>
                <Text fontSize="12px" color="white">
                  Be sure to check your spam folder. Still can&apos;t find it?{' '}
                  <StyledLink to={`/auth/login/forgot-password/${email}`}>Resend email</StyledLink>
                </Text>
              </Stack>
            </Stack>
          </Box>
        </Box>
      </Stack>
    </Stack>
  );
};

export default Render;
