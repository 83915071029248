import { ReactNode } from 'react';

import AuthConfigContext, { AuthConfigContextInterface } from './config-context';

const AuthConfigProvider = ({ children }: { children: ReactNode }) => {
  let params: AuthConfigContextInterface;
  const { auth0Config } = window;

  try {
    const config = JSON.parse(decodeURIComponent(escape(window.atob(auth0Config))));

    const leeway = config.internalOptions.leeway;
    if (leeway) {
      const convertedLeeway = parseInt(leeway, 10);

      if (!isNaN(convertedLeeway)) {
        config.internalOptions.leeway = convertedLeeway;
      }
    }

    params = {
      isAuth0Hosted: true,
      domain: config.auth0Domain,
      clientID: config.clientID,
      redirectUri: config.callbackURL,
      responseType: 'code',
      overrides: {
        __tenant: config.auth0Tenant,
        __token_issuer: config.authorizationServer.issuer,
      },
      ...config.internalOptions,
    };
  } catch (e) {
    // No Auth0 Config
    params = {
      isAuth0Hosted: false,
      domain: process.env.REACT_APP_AUTH0_DOMAIN!,
      clientID: process.env.REACT_APP_AUTH0_CLIENT_ID!,
      redirectUri: process.env.REACT_APP_AUTH0_CALLBACK_URL!,
      responseType: 'code',
    };
  }

  return <AuthConfigContext.Provider value={params}>{children}</AuthConfigContext.Provider>;
};

export default AuthConfigProvider;
