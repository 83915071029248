import { Link } from 'react-router-dom';

import { useParams } from 'react-router-dom';
import { Box, Heading, Stack, Text } from '@vinomofo/components';
import { getHashParams } from '../../../utils/hash-params';
import styled from 'styled-components';
import { getParameterFromURLSearch } from '../../../utils/get-parameter-from-url';

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 500;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const ExternalLink = styled('a')`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 500;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const Render = () => {
  const { email: encodedEmail } = useParams<{ email: string }>();
  const email = decodeURIComponent(encodedEmail);

  const hashParams = getHashParams(window.location.hash);
  const returnTo = hashParams.returnTo || 'https://vinomofo.com';
  const url = `/auth/resend/${encodeURIComponent(email)}?returnTo=${returnTo}`;
  const redirect_uri = getParameterFromURLSearch('redirect_uri', window.location.search);
  const storefrontUrl = redirect_uri?.includes('vinomofo.com.sg')
    ? 'https://www.vinomofo.com.sg/'
    : 'https://www.vinomofo.com/';

  return (
    <Stack space={3}>
      <Text
        fontFamily="heading"
        fontSize={['28px', '32x', '44px']}
        fontWeight={700}
        color="white"
        textAlign={['center', 'left', 'left']}
      >
        🚀 WHOOOSH!
      </Text>
      <Box width="100%" maxWidth={['auto', '500px']} fontWeight="500">
        <Stack space={2}>
          <Text fontSize={['13px', '14px', '15px']} textAlign={['center', 'left', 'left']} variant="sm" color="white">
            Did you catch that? Our email to <strong>{email}</strong>.
          </Text>
          <Text fontSize={['13px', '14px', '15px']} textAlign={['center', 'left', 'left']} variant="sm" color="white">
            Click the self-destructive link within the next 10 minutes!
          </Text>
          <Box mb="20px">
            <Text fontSize={['13px', '14px', '15px']} textAlign={['center', 'left', 'left']} variant="sm" color="white">
              Sign in, close this window, and dive into the action.
            </Text>
          </Box>
          <Stack space={2}>
            <div>
              <Heading as="h5" fontSize="14px" color="#77B204">
                CAN&apos;T SEE YOUR EMAIL?
              </Heading>
              <Box height="2px" />
              <Text fontSize="12px" color="white">
                Sometimes it lands in your spam folder. Worth checking there.
              </Text>
            </div>
            <div>
              <Heading as="h5" fontSize="14px" color="#77B204">
                STILL CAN&apos;T FIND YOUR LINK?
              </Heading>
              <Box height="2px" />
              <Text fontSize="12px" color="white">
                <StyledLink to={url}>Click here</StyledLink> to request a new link.
              </Text>
            </div>
            <div>
              <Heading as="h5" fontSize="14px" color="#77B204">
                GOT TROUBLES WITH THE OL&apos; EMAIL?
              </Heading>
              <Box height="5px" />
              <Text fontSize="12px" color="white">
                You can reach out to our{' '}
                <ExternalLink href={`${storefrontUrl}contact`} target="_blank">
                  Customer Service
                </ExternalLink>{' '}
                team for help.
              </Text>
            </div>
          </Stack>
        </Stack>
      </Box>
    </Stack>
  );
};

export default Render;
