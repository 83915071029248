import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Text } from '@vinomofo/components';
import { getParameterFromURLSearch } from '../../utils/get-parameter-from-url';

const ExternalLink = styled('a')`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 500;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

interface RenderProps {
  color?: string;
}

const Render: React.FC<RenderProps> = ({ color = '#626468' }): JSX.Element => {
  const redirect_uri = getParameterFromURLSearch('redirect_uri', window.location.search);
  const storefrontUrl = redirect_uri?.includes('vinomofo.com.sg')
    ? 'https://www.vinomofo.com.sg/'
    : 'https://www.vinomofo.com/';

  return (
    <div>
      <Text fontSize="12px" color={color}>
        By continuing, you&apos;re agreeing to our &nbsp;
        <ExternalLink href={`${storefrontUrl}terms`} target="_blank">
          Terms of Use
        </ExternalLink>
        ,&nbsp;
        <ExternalLink href={`${storefrontUrl}privacy`} target="_blank">
          Privacy Policy
        </ExternalLink>
        .
      </Text>
    </div>
  );
};

Render.propTypes = {
  color: PropTypes.string,
};

export default Render;
