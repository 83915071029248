import { datadogLogs } from '@datadog/browser-logs';
import { dataDogEnabled } from './environment-helpers';

export const logInfo = (message: string, context?: Record<never, never>): void =>
  dataDogEnabled() ? datadogLogs.logger.info(message, context) : console.log(message, context);

export const logError = (message: string, context?: Record<never, never>): void =>
  dataDogEnabled() ? datadogLogs.logger.error(message, context) : console.error(message, context);

export const logDebug = (message: string, context?: Record<never, never>): void =>
  dataDogEnabled() ? datadogLogs.logger.debug(message, context) : console.debug(message, context);
