import { MouseEventHandler, PropsWithChildren, ReactNode } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Box, Stack, Text } from '@vinomofo/components';
import { FacebookIcon, GoogleIcon } from '@vinomofo/icons';

import useAuth from '../../hooks/use-auth';
import { trackUserLoginOption } from '../../utils/track-event';

const Link = styled('a')`
  text-decoration: none;
  transition: scale 0.3s ease-in;

  &:hover {
    transform: scale(1.05);
  }
`;

const SocialLink = ({
  children: icon,
  connection,
  title,
}: {
  children: ReactNode;
  connection: string;
  title: string;
}) => {
  const { loginWithSocialProvider } = useAuth();

  const handleClick: MouseEventHandler = (event) => {
    event.preventDefault();
    loginWithSocialProvider(connection);
    trackUserLoginOption(connection);
  };

  return (
    <Link onClick={handleClick} title={title} href={`#${connection}`}>
      <Box height="44px" width="44px">
        {icon}
      </Box>
    </Link>
  );
};

const IconContainer = ({ backgroundColor, children }: PropsWithChildren<{ backgroundColor: string }>) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      borderRadius="50%"
      width="44px"
      height="44px"
      backgroundColor={backgroundColor}
      color="white"
    >
      {children}
    </Box>
  );
};

const GoogleLogin = (): JSX.Element => {
  return (
    <SocialLink connection="google-oauth2" title="Google">
      <IconContainer backgroundColor="#ffffff">
        <GoogleIcon size="44px" />
      </IconContainer>
    </SocialLink>
  );
};

const FacebookLogin = (): JSX.Element => {
  return (
    <SocialLink connection="facebook" title="Facebook">
      <IconContainer backgroundColor="#325a9d">
        <FacebookIcon size="24px" />
      </IconContainer>
    </SocialLink>
  );
};

/* const LinkedInLogin = (): JSX.Element => {
  return (
    <SocialLink connection="linkedin" title="LinkedIn">
      <IconContainer backgroundColor="#0077b9">
        <LinkedInIcon size="24px" />
      </IconContainer>
    </SocialLink>
  );
}; */

interface RenderProps {
  color?: string;
  title?: string;
}

const Render: React.FC<RenderProps> = ({ color = 'white', title = 'Continue with' }): JSX.Element => {
  return (
    <Stack space={1}>
      <Text fontFamily="heading" fontSize={['20px', '20px', '24px']} fontWeight={700} color={color} textAlign="center">
        {title}
      </Text>
      <Box display="flex" alignItems="center" flexDirection="column">
        <Stack horizontal space={2}>
          <GoogleLogin />
          <FacebookLogin />
          {/*<LinkedInLogin />*/}
        </Stack>
      </Box>
    </Stack>
  );
};

Render.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string,
};

export default Render;
