type FieldValue = string | number | boolean;
type FormValues = { [key: string]: undefined | FieldValue };
type FieldError = { [key: string]: string };
type FormErrors = { [key: string]: string };

type Validator = (values: FormValues) => FieldError;
type ValidateFn = (values: FormValues) => FormErrors;

const DATE_STRING_FORMAT = 'DD/MM/YYYY';
const DATE_STRING_RE = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/([12][0-9]{3})$/;

export const dateStringFromFormattedDateString = (dateString: string): string => {
  return dateString.replace(DATE_STRING_RE, '$3-$2-$1');
};

export const validateDate = (
  name: string,
  { message, ...config }: { equals?: Date; before?: Date; after?: Date; message?: string },
): Validator => {
  const CHECKS: Record<'equals' | 'before' | 'after', (value: Date, other: Date) => boolean> = {
    equals: (value, other) => value.getTime() === other.getTime(),
    before: (value, other) => value.getTime() < other.getTime(),
    after: (value, other) => value.getTime() > other.getTime(),
  };

  return (values) => {
    const str = values[name]?.toString();

    if (undefined === str) return {};

    let value;

    if (!DATE_STRING_RE.test(str)) {
      return { [name]: `invalid format - expected ${DATE_STRING_FORMAT}` };
    } else {
      value = new Date(dateStringFromFormattedDateString(str));
      value.setHours(0, 0, 0, 0);
    }

    if (undefined !== config.equals && !CHECKS.equals(value, config.equals)) {
      return { [name]: message || `must equal ${config.equals.toLocaleDateString('en-au')}` };
    }

    if (undefined !== config.before && !CHECKS.before(value, config.before)) {
      return { [name]: message || `must be before ${config.before.toLocaleDateString('en-au')}` };
    }

    if (undefined !== config.after && !CHECKS.after(value, config.after)) {
      return { [name]: message || `must be after ${config.after.toLocaleDateString('en-au')}` };
    }

    return {};
  };
};

export const validateFormat = (
  name: string,
  { message = 'is invalid', format }: { format: RegExp; message?: string },
): Validator => {
  return (values) => {
    const value = values[name]?.toString() || '';

    if (!format.test(value)) {
      return { [name]: message };
    }

    return {};
  };
};

export const validatePresence = (name: string, { message = 'is required' }: { message?: string } = {}): Validator => {
  return (values) => {
    const value = values[name];

    if (null === value || undefined === value) {
      return { [name]: message };
    }

    if ('string' === typeof value) {
      if (0 === value.length || /^\s*$/.test(value)) {
        return { [name]: message };
      }
    }

    return {};
  };
};

export const validateNameBasedOnFlag = (
  name: string,
  validateFlag: boolean,
  { message = 'is required' }: { message?: string } = {},
): Validator => {
  return (values) => {
    const value = values[name];
    if (validateFlag) {
      if (null === value || undefined === value) {
        return { [name]: message };
      }

      if ('string' === typeof value) {
        if (0 === value.length) {
          return { [name]: message };
        }

        if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/.test(value)) {
          return { [name]: 'Password is too weak. Spice it up Mofo.' };
        }
      }
    }

    return {};
  };
};

export const validateAcceptance = (name: string, { message = 'must be accepted' }: { message?: string }): Validator => {
  return (values) => {
    if (true !== values[name]) {
      return { [name]: message };
    }

    return {};
  };
};

export const validateBirthdate = (name: string): Validator => {
  const minimumAge = parseInt(process.env.MINIMUM_AGE_TO_PURCHASE || '18');

  const minimum = new Date();
  minimum.setFullYear(minimum.getFullYear() - minimumAge);
  minimum.setHours(0, 0, 0, 0);

  return validate([
    validatePresence(name),
    validateDate(name, { before: minimum, message: `Sorry, we can’t sell wine to anyone under ${minimumAge}` }),
  ]);
};

export const validateEmail = (name: string): Validator => {
  const format = /^(?:[^@]+)@(?:[^.@]+)(?:\.[a-z1-3]+)+$/i;
  return validate([
    validatePresence(name, { message: 'please enter a valid email' }),
    validateFormat(name, { format, message: `that email doesn't look right.. typo, Mofo?` }),
  ]);
};

export const validate = (validators: Validator[]): ValidateFn => {
  return (values) => {
    return validators.reduce((errors, validator) => {
      return { ...errors, ...validator(values) };
    }, {});
  };
};
