import { Link as RouteLink } from 'react-router-dom';
import styled from 'styled-components';

import { Box, Heading, Text } from '@vinomofo/components';
import { getHashParams } from '../../utils/hash-params';

const Link = styled(RouteLink)`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 500;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const Render = (props: { message: string }) => {
  const hashParams = getHashParams(window.location.hash);
  const returnTo = hashParams.returnTo || 'https://vinomofo.com';
  return (
    <div>
      <Heading as="h5" fontSize="14px" color="#77B204" textTransform="uppercase">
        {props.message}
      </Heading>
      <Box height="5px" />
      <Text fontSize="12px" color="white">
        <Link to={`/auth/login/using-password?returnTo=${returnTo}`}>Click here</Link> to login with your email &amp;
        password combo.
      </Text>
    </div>
  );
};

export default Render;
