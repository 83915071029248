import { ReactNode, useMemo, useReducer } from 'react';

import Context, { ApplicationContextInterface } from './context';
import { reducer } from './reducer';
import { initialApplicationState } from './state';

const Provider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, initialApplicationState);

  const context: ApplicationContextInterface = useMemo(
    () => ({
      ...state,
      setError: (e) => dispatch({ type: 'SHIT_HAPPENED', error: e }),
      setFormData: (data) => dispatch({ type: 'KEEP_DATA', formData: data }),
    }),
    [state],
  );

  return <Context.Provider value={context}>{children}</Context.Provider>;
};

export default Provider;
