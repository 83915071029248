import { createContext } from 'react';

import { LoginCredentials, PasswordlessOptions, VerifyPasswordlessOptions } from './types';

export interface AuthClientContextInterface {
  startPasswordlessLogin: (options: PasswordlessOptions) => Promise<unknown>;
  startPasswordlessSignup: (options: PasswordlessOptions) => Promise<unknown>;
  verifyPasswordless: (options: VerifyPasswordlessOptions) => Promise<unknown>;
  loginWithCredentials: (credentials: LoginCredentials) => Promise<unknown>;
  loginWithSocialProvider: (provider: string) => void;
  forgotPassword: (options: PasswordlessOptions) => Promise<unknown>;
}

const stub = (): never => {
  throw new Error('You forgot to wrap your component in <AuthClient>.');
};

const initialClientContext: AuthClientContextInterface = {
  startPasswordlessLogin: stub,
  startPasswordlessSignup: stub,
  verifyPasswordless: stub,
  loginWithCredentials: stub,
  loginWithSocialProvider: stub,
  forgotPassword: stub,
};

const AuthClientContext = createContext(initialClientContext);

export default AuthClientContext;
