import { Redirect, Route, HashRouter as Router, Switch } from 'react-router-dom';

import { GlobalStyle, ThemeProvider } from '@vinomofo/components';

import ApplicationStateProvider from './contexts/application-state/provider';
import AuthProvider from './contexts/auth/auth-provider';
import Profile from './pages/enrich-profile';
import Join from './pages/join';
import LoginWithCode from './pages/login/with-code/start';
import VerifyLoginWithCode from './pages/login/with-code/verify';
import VerifyJoinWithCode from './pages/login/with-code/verify-join';
import LoginWithCredentials from './pages/login/with-credentials';
import ForgotPassword from './pages/login/forgot-password';
import ForgotPasswordConFirmation from './pages/login/forgot-password-confirmation';
import { logInfo } from './utils/logger';
import ErrorNotifierProvider from './contexts/error-notifier/provider';
import Layout from './components/layout';

const RenderAuthPages = () => {
  return (
    <AuthProvider>
      <Router>
        <Switch>
          <Route exact path="/auth/login" component={LoginWithCode} />
          <Route exact path="/auth/login/using-password" component={LoginWithCredentials} />
          <Route exact path="/auth/login/verify/:email" component={VerifyLoginWithCode} />
          <Route exact path="/auth/login/forgot-password/:email?" component={ForgotPassword} />
          <Route exact path="/auth/login/forgot-password-confirmation/:email" component={ForgotPasswordConFirmation} />
          <Route exact path="/auth/resend/:email" component={LoginWithCode} />
          <Route exact path="/auth/join" component={Join} />
          <Route exact path="/auth/join/verify/:email" component={VerifyJoinWithCode} />
        </Switch>
      </Router>
    </AuthProvider>
  );
};

const RenderIdentityPages = () => {
  logInfo('Into the 2nd renderer');
  return (
    <Router>
      <Switch>
        <Route exact path="/id/finalize/:token" component={Profile} />
      </Switch>
    </Router>
  );
};

const Render = () => {
  logInfo('Into the renderer');
  return (
    <ErrorNotifierProvider>
      <ApplicationStateProvider>
        <ThemeProvider>
          <GlobalStyle />
          <Router>
            <Switch>
              <Layout>
                <Route exact path="/">
                  <Redirect to="/auth/login/using-password" />
                </Route>
                <Route path="/auth/" component={RenderAuthPages} />
                <Route path="/id/" component={RenderIdentityPages} />
              </Layout>
            </Switch>
          </Router>
        </ThemeProvider>
      </ApplicationStateProvider>
    </ErrorNotifierProvider>
  );
};

export default Render;
